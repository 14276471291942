<script>
import DataLayer from '@services/data-layer/index'
import formatDate  from './../../../utils/format-date-full'

const premiumService = DataLayer.subscriptions

export default {
    components: { },
    data() {
        return {
            crumbs: [
                { label: 'Account Management', to: '/account' },
                { label: 'Cancel Premium' },
            ],
            isLoading: false,
        }
    },
    page() {
        const title = 'Cancel Premium'
        const description = 'Cancel Premium'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        user() {
            return this.$store.getters['member/currentMember']
        },
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        }
    },
    methods: {
        formatDate,
        async handleSubscriptionUpdate() {
            const subscriptions = await premiumService.getSubscriptionsByMemberId(this.user.id)
            this.$store.commit('premium/SET_SUBSCRIPTIONS', subscriptions.body)
            const activeSubscription = this.$store.getters['premium/activeSubscription']

            if(activeSubscription) {
                const transaction = await premiumService.getCheckoutById(activeSubscription.subscriptionsCheckoutId.id)
                this.$store.commit('premium/SET_ACTIVE_TRANSACTION', transaction.body)
            }
            else this.$store.commit('premium/SET_ACTIVE_TRANSACTION', null)
        },
        async cancelSubscription() {
            this.isLoading = true
            try {
                await premiumService.cancelSubscription(this.currentSubscription)
                await this.handleSubscriptionUpdate()
                this.$router.push('/account/cancellation-feedback')
                this.isLoading = false
            } catch (error) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('SOMETHING_WENT_WRONG'),
                    type: 'danger',
                })
                this.isLoading = false
            }
        }
    }
}
</script>

<template>
    <div class="cancel-premium-container">
        <BaseBreadcrumbs :crumbs="crumbs"/>
        <div class="main-container text-center">
            <div class="title">Are you sure?</div>
            <div class="subtitle fw-medium">
                If you cancel, you'll change to our free plan on {{ formatDate(new Date(currentSubscription.endTime)) }}.
                <br>
                Here's how your listening will change on that date:
            </div>
            <div class="points">
                <div class="point">
                    <BaseIcon
                        class="point-icon"
                        name="disc"
                    />
                    <div class="point-description fw-medium">No more hi-res audio</div>
                </div>
                <div class="point">
                    <BaseIcon
                        class="point-icon"
                        name="cloud-download"
                    />
                    <div class="point-description fw-medium">
                        No more downloads
                        <br>
                        on our mobile apps to listen offline
                    </div>
                </div>
                <div class="point">
                    <BaseIcon
                        class="point-icon"
                        name="play-reverse"
                    />
                    <div class="point-description fw-medium">No more on-demand playback</div>
                </div>
                <div class="point">
                    <div class="mixes-count-number fw-black">100,000</div>
                    <div class="point-description fw-medium">
                        You'll no longer have instant access to over 100,000
                        <br>
                        exclusive mixes
                    </div>
                </div>
            </div>
            <div class="actions">
                <BaseButton
                    class="action-button"
                    button-type="tagBlack"
                    size="medium"
                    type="button"
                    @click="() => {}"
                >Keep Premium</BaseButton>

                <BaseButton
                    class="action-button"
                    button-type="tagBlack"
                    size="medium"
                    type="button"
                    @click="cancelSubscription"
                >
                    <span class="button-content">Continue to cancel<BaseIcon
                        v-if="isLoading"
                        class="loading-icon spin"
                        name="spinner"
                    /></span>
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.cancel-premium-container {
    padding-bottom: $s-h;
}

.main-container {
    margin-top: $s-l;
}

.title {
    @extend %h1;
}

.subtitle {
    font-size: 14px;
    line-height: 150%;
}

.points {
    display: flex;
    flex-direction: column;
    gap: $s-l;
    margin-top: $s-xl;
}

.point {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s-xs;
}

.point-icon {
    max-width: 100px;
    max-height: 100px;
    fill: $c-white;
}

.point-description {
    font-size: 14px;
    line-height: 150%;
}

.mixes-count-number {
    font-size: 48px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: $s-l;

    .action-button {
        text-transform: unset;
        font-weight: unset;
        padding: $s-m $s-l;
    }
}

.button-content {
    display: flex;
    align-items: center;
    gap: $s-m;
}

.loading-icon {
    max-width: 20px;
    max-height: 20px;
    fill: $c-white;
}

@include tablet {
    .subtitle {
        font-size: 16px;
    }

    .points {
        gap: $s-xxl;
        margin-top: $s-h;
    }

    .point {
        gap: $s-s;
    }

    .point-icon {
        max-width: 140px;
        max-height: 140px;
    }

    .point-description {
        font-size: 16px;
    }

    .mixes-count-number {
        font-size: 96px;
    }

    .actions {
        margin-top: $s-xxl;
    }
}
</style>
