<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: [String, Number],
            default: '',
        },
    },
    data() {
        return {
            showPassword: false,
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: event => this.$emit('input', event.target.value),
            }
        },
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword
        },
    },
}
</script>

<template>
    <div class="input-container">
        <input
            :type="showPassword ? 'text' : type"
            :value="value"
            class="input"
            v-bind="$attrs"
            v-on="listeners"
        >
        <div
            v-if="type === 'password'"
            class="password-toggle"
            @click="togglePasswordVisibility"
        >
            <BaseIcon
                class="eye-icon"
                :name="showPassword ? 'eye-open' : 'eye-closed'"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.input {
    @extend %input;
}

.input-container {
    position: relative;

    &.error .input {
        background-color: transparentize($c-red, 0.95);
    }
}

.password-toggle {
    position: absolute;
    top: 0;
    right: $s-m;
    display: flex;
    align-items: center;
    height: $size-input-height;
    cursor: pointer;
}

.eye-icon {
    max-width: 22px;
    max-height: 22px;
    fill: $c-text-faded;
    opacity: 0.5;
}
</style>
