<script>
import DataLayer from '@services/data-layer/index'
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
    page() {
        const title = `Account - ${this.isPassSet ? 'Set' : 'Change'} Password`
        const description = `Account - ${this.isPassSet ? 'Set' : 'Change'} Password`
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    mixins: [validationMixin],
    data() {
        return {
            user: null,
            password: '',
            newPassword: '',
            newPasswordConfirmation: '',
            token: '',
            crumbs: [
                { label: 'Account Management', to: '/account' },
                { label: `${this.isPassSet ? 'Set' : 'Change'} Password` },
            ],
        }
    },
    computed: {
        member() {
            return this.$store.state.member.currentMember
        },
        isPassSet() {
            return this.$store.state.member.passState === 'set'
        },
    },
    mounted() {
        this.user = { ...this.$store.state.member.currentMember }
    },
    validations: {
        password: {
            required,
        },
        newPassword: {
            required,
        },
        newPasswordConfirmation: {
            required,
            sameAsPassword: sameAs(function () {
                return this.newPassword
            }),
        },
    },
    methods: {
        ...mapActions('auth', ['logIn', 'logOut']),
        checkPassword() {
            if (!this.isPassSet) {
                this.changePassword()
                return
            }
            this.$v.$touch()
            if (
                !this.$v.password.required ||
                !this.$v.newPassword.required ||
                !this.$v.newPasswordConfirmation.required
            ) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('MISSING_REQUIRED_FIELDS'),
                    type: 'danger',
                })
                return
            }
            DataLayer.auth
                .login({
                    email: this.member.email,
                    password: this.password,
                })
                .then(token => {
                    this.token = token
                    this.changePassword()
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$store.dispatch('appearance/handleNotification', {
                            message: this.$m('WRONG_PASSWORD'),
                            type: 'danger',
                        })
                    }
                })
        },
        changePassword() {
            this.$v.$touch()
            if (!this.$v.newPassword.required || !this.$v.newPasswordConfirmation.required) {
                return
            }
            if (!this.$v.newPasswordConfirmation.sameAsPassword) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('PASSWORD_MATCH'),
                    type: 'danger',
                })

                return
            }
            this.user.password = this.newPassword
            DataLayer.members
                .edit({ id: this.user.id, data: this.user })
                .then(user => {
                    this.$store.commit('member/SET_CURRENT_MEMBER', user.body)
                    this.$store.commit('member/SET_PASS_STATE', 'set')
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m(this.isPassSet ? 'PASSWORD_UPDATED' : 'PASSWORD_SET'),
                        type: 'success',
                    })
                    this.redirectToAccountPage()
                })
                .catch(_error => {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                })
        },
        redirectToAccountPage() {
            this.$router.push('/account')
        },
    },
}
</script>

<template>
    <div>
        <BaseBreadcrumbs :crumbs="crumbs"/>
        <h1 class="title">
            {{ isPassSet ? 'Change Password' : 'Set Password' }}
        </h1>
        <label
            v-if="!isPassSet"
            class="subtitle"
        >Setting a password will allow you to login using your email.</label>
        <form
            class="form"
            autocomplete="off"
            @submit.prevent="checkPassword"
        >
            <div class="form-container">
                <div
                    v-if="isPassSet"
                    class="field-container"
                >
                    <label class="label">Current Password</label>
                    <BaseInput
                        v-model="password"
                        :class="['field', $v.password.$error && 'error']"
                        type="password"
                        autocomplete="password"
                        placeholder="Enter current password"
                        @blur="$v.password.$touch()"
                    />
                </div>
                <div class="field-container">
                    <label class="label">New Password</label>
                    <BaseInput
                        v-model="newPassword"
                        :class="['field', $v.newPassword.$error && 'error']"
                        type="password"
                        autocomplete="new-password"
                        placeholder="Enter new password"
                        aria-autocomplete="none"
                        @blur="$v.newPassword.$touch()"
                    />
                </div>
                <div class="field-container">
                    <label class="label">Confirm New Password</label>
                    <BaseInput
                        v-model="newPasswordConfirmation"
                        :class="['field', $v.newPasswordConfirmation.$error && 'error']"
                        type="password"
                        autocomplete="new-password"
                        placeholder="Enter new password again"
                        aria-autocomplete="none"
                        @blur="$v.newPasswordConfirmation.$touch()"
                    />
                </div>
            </div>
            <div class="actions">
                <BaseButton
                    class="cancel-button"
                    button-type="transparent"
                    size="medium"
                    type="button"
                    @click="redirectToAccountPage"
                >Cancel</BaseButton>
                <BaseButton
                    class="save-button"
                    button-type="tagBlack"
                    size="medium"
                    type="submit"
                    @click="() => {}"
                >{{ isPassSet ? 'Change password' : 'Set password' }}</BaseButton>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.title {
    margin-top: 0;
}

.subtitle {
    @extend %contentSubtitleM;

    display: block;
    margin-bottom: $s-xxl;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: $s-l;
}

.field-container {
    display: flex;
    flex-direction: column;
    gap: $s-m;
}

.label {
    @extend %contentSubtitleM;
}

.birthday-fields {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-gap: $s-xs;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $s-xl;
    margin-top: $s-xxl;

    .cancel-button {

    }

    .save-button {
        text-transform: unset;
        font-weight: unset;
        padding: $s-m $s-l;
    }
}

@include tablet {
    .birthday-fields {
        grid-template-columns: 1fr 2fr 4fr;
        grid-gap: $s-s;
    }

    .actions {
        justify-content: flex-end;
    }
}
</style>
