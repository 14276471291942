<script>
import DataLayer from '@services/data-layer/index'
import formatDate  from './../../../utils/format-date-full'
import { feedbackMessageMemberId } from '../../../configuration'

export default {
    data() {
        return {
            selectedChoice: '',
            textFeedback: '',
            isLoading: false,
            crumbs: [
                { label: 'Account Management', to: '/account' },
                { label: 'Cancel Premium' },
                { label: 'Cancellation feedback' },
            ],
            hasBeenSent: false,
            hasError: false,
        }
    },
    page() {
        const title = 'Cancel Premium feedback'
        const description = 'Cancel Premium feedback'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        },
    },
    methods: {
        formatDate,
        async submitFeedback() {
            if (!this.selectedChoice || (this.selectedChoice === 'other' && !this.textFeedback)) {
                return this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('MISSING_REQUIRED_FIELDS'),
                    type: 'warning',
                })
            }

            const message = `Reason: ${this.selectedChoice} \n ${this.selectedChoice === 'other' ? 'More details:' + this.textFeedback : ''}`

            this.isLoading = true

            try {
                await DataLayer.messages.sendMessage({
                    message: message.replace(/\n/g, '<br>'),
                    subject: 'Cancellation feedback',
                    content: "FRISKY Premium cancellation feedback",
                    recipientId: feedbackMessageMemberId,
                    senderId: this.$store.getters['member/currentMember'].id
                })
                this.isLoading = false
                this.hasBeenSent = true
            } catch (_error) {
                this.isLoading = false
                this.hasError = true
            }
        },
        handleSuccessMessageClose() {
            this.hasBeenSent = false
            this.$router.push('/account')
        },
        handleErrorMessageClose() {
            this.hasError = false
        }
    }
}
</script>

<template>
    <div>
        <BaseBreadcrumbs :crumbs="crumbs"/>
        <div class="cancellation-feedback-container">
            <h1 class="title">Premium cancelled. :(</h1>
            <div class="subtitles">
                <p class="subtitle text-white">Your subscription ends on {{ formatDate(new Date(currentSubscription.endTime)) }}.</p>
                <p class="subtitle text-white">To help us improve Premium, please tell us why you cancelled:</p>
            </div>
            <form @submit.prevent="submitFeedback">
                <h3 class="feedback-header text-white fw-bold">Please tell us why you cancelled Premium?</h3>
                <div class="choices">
                    <div class="radio-group">
                        <input
                            id="switch-plan"
                            v-model="selectedChoice"
                            type="radio"
                            class="radio-input"
                            name="switch-plan"
                            value="I am switching to a different plan"
                        >
                        <label
                            class="radio-label"
                            for="switch-plan"
                        >I am switching to a different plan</label>
                    </div>
                    <div class="radio-group">
                        <input
                            id="cant-afford"
                            v-model="selectedChoice"
                            type="radio"
                            class="radio-input"
                            name="cant-afford"
                            value="I cant afford Premium anymore"
                        >
                        <label
                            class="radio-label"
                            for="cant-afford"
                        >I cant afford Premium anymore</label>
                    </div>
                    <div class="radio-group">
                        <input
                            id="cant-find-music"
                            v-model="selectedChoice"
                            type="radio"
                            class="radio-input"
                            name="cant-find-music"
                            value="I cant find music that I like"
                        >
                        <label
                            class="radio-label"
                            for="cant-find-music"
                        >I cant find music that I like</label>
                    </div>
                    <div class="radio-group">
                        <input
                            id="technical-issues"
                            v-model="selectedChoice"
                            type="radio"
                            class="radio-input"
                            name="technical-issues"
                            value="Ive had technical issues with FRISKY Premium"
                        >
                        <label
                            class="radio-label"
                            for="technical-issues"
                        >Ive had technical issues with FRISKY Premium</label>
                    </div>
                    <div class="radio-group">
                        <input
                            id="dont-use-enough"
                            v-model="selectedChoice"
                            type="radio"
                            class="radio-input"
                            name="dont-use-enough"
                            value="I dont use FRISKY Premium enough"
                        >
                        <label
                            class="radio-label"
                            for="dont-use-enough"
                        >I dont use FRISKY Premium enough</label>
                    </div>
                    <div class="radio-group">
                        <input
                            id="other"
                            v-model="selectedChoice"
                            type="radio"
                            class="radio-input"
                            name="other"
                            value="other"
                        >
                        <label
                            class="radio-label"
                            for="other"
                        >Other (Please specify below)</label>
                    </div>
                </div>
                <div
                    v-if="selectedChoice === 'other'"
                    class="text-feedback"
                >
                    <h3 class="feedback-header fw-bold">Please tell us more about why you are cancelling:</h3>
                    <textarea
                        v-model="textFeedback"
                        placeholder="Please specify"
                        class="text-feedback-input"
                    />
                </div>
                <div class="actions">
                    <BaseButton
                        class="action-button"
                        button-type="tagBlack"
                        size="medium"
                        type="submit"
                        :disabled="isLoading"
                        @click="() => {}"
                    ><span class="button-content">Send feedback <BaseIcon
                        v-if="isLoading"
                        class="loading-icon spin"
                        name="spinner"
                    /></span></BaseButton>
                </div>
            </form>
        </div>
        <BaseModal
            :show="hasBeenSent"
            @close="handleSuccessMessageClose"
        >
            <h5>Thank you for your feedback!</h5>
            <p class="text-faded">We appreciate you taking the time to help us improve.</p>
        </BaseModal>
        <BaseModal
            :show="hasError"
            @close="handleErrorMessageClose"
        >
            <h5>Something went wrong</h5>
            <p
                class="text-faded
            "
            >Please try again later.</p>
        </BaseModal>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

:root {
    --text-size: 14px;
}

.cancellation-feedback-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;
}

.title {
    margin-top: $s-l;
}

.subtitle {
    font-size: var(--text-size);
    line-height: 150%;
}

.feedback-header {
    font-size: var(--text-size);
    line-height: 250%;
}

.radio-group {
    line-height: 250%;
}

.radio-input {
    margin-right: $s-m;
}

.radio-label {
    font-size: var(--text-size);
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .action-button {
        margin-top: $s-l;
        padding: $s-m $s-l;
        text-transform: unset;
        font-weight: unset;
    }
}

.button-content {
    display: flex;
    align-items: center;
    gap: $s-m;
}

.text-feedback-input {
    width: 732px;
    max-width: 100%;
    height: 185px;
    padding: $s-m;
    font-size: var(--text-size);
    line-height: 150%;
    border: 1px solid transparent;
    outline: 1px solid $c-emperor;
    border-radius: 5px;
    background-color: $c-emperor;
    color: $c-white;

    &::placeholder {
        color: $c-text-faded;
    }

    &:focus {
        outline: 1px solid $c-nevada-grey;
    }
}

.loading-icon {
    max-width: 20px;
    max-height: 20px;
    fill: $c-white;
}

@include tablet {
    :root {
        --text-size: 16px;
    }

    .cancellation-feedback-container {
        gap: $s-xl;
    }

    .title {
        margin-top: $s-m;
    }

    .actions {
        margin-top: $s-xxl;
    }
}
</style>
