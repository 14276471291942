<script>
import PremiumLayout from '../../layouts/premium.vue'

export default {
    components: { PremiumLayout },
}
</script>

<template>
    <PremiumLayout>
        <div :class="$style.wrapper">
            <div :class="$style.background" />
            <div :class="$style.main">
                <router-view
                    :key="$route.path"
                    :class="$style.childView"
                />
            </div>
        </div>
    </PremiumLayout>
</template>

<style lang="scss" module>
@use '@design' as *;

.wrapper {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.background {
    @include fill;

    position: fixed;
    background: $c-grey-woodsmoke;
    background-image: url('/images/subscrpiption-background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.main {
    position: relative;
    width: 1024px;
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    text-align: center;
}

.childView {
    padding-bottom: $s-h;
}

@include tablet {
    .main {
        padding: $s-h 0;
        text-align: left;
    }
}
</style>
