<script>
import { centsToDollars } from '../../../../utils/format-price'
import formatDate from '../../../../utils/format-date-full'

export default {
    name: 'SubscriptionHeader',
    props: {
        isStripePortalLoading: {
            type: Boolean,
            default: false,
        },
        allowUpdateCard: {
            type: Boolean,
        }
    },
    computed: {
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        },
        isEntitledForTrial() {
            return !this.$store.getters['premium/hasEverBeenPremium']
        },
        hasPaidViaMobile() {
            return this.$store.getters['premium/hasPaidViaMobile']
        },
        hasActivePlan() {
            return this.currentSubscription && (new Date(this.currentSubscription.endTime) > new Date())
        },
        currentPlan() {
            return this.hasActivePlan ? `Premium` : 'FRISKY Free'
        },
        paymentMethod() {
            return this.$store.getters['premium/paymentMethod']
        },
        subtitle() {
            if(!this.hasActivePlan) return ''

            if (this.paymentMethod === 'apple') {
                return 'Your subscription is managed through Apple. To manage your subscription, go to iOS Settings > Apple ID > Subscriptions'
            }

            if (this.paymentMethod === 'google') {
                return 'Your subscription is managed through Google Play. To manage your subscription, go to Google Play Store > Account > Subscriptions"'
            }

            if (this.paymentMethod === 'manual') {
                return `Enjoy free Premium subscription on us! It will expire on ${formatDate(new Date(this.currentSubscription.endTime))}`
            }

            if(this.currentSubscription?.cancelTime) {
                return `Your subscription is canceled. It will expire on ${formatDate(new Date(this.currentSubscription.endTime))}`
            }

            return `Your next bill is for ${ centsToDollars(this.currentSubscription.plan.price) } on ${ formatDate(new Date(this.currentSubscription.endTime)) }`
        }
    },
    methods: {
        goToEditProfile() {
            this.$router.push('/account/edit-profile')
        },
        explorePlans() {
            this.$router.push('/premium/view-plans')
        }
    },
};
</script>

<template>
    <div class="subscription-header">
        <BaseCard class="plan-card">
            <h2 class="plan-header">Your plan</h2>
            <h3 class="plan-name text-faded">{{ currentPlan }}</h3>
            <p class="plan-subtitle text-faded">{{ subtitle }}</p>
            <div
                v-if="isEntitledForTrial"
                class="plan-actions"
            >
                <BaseButton
                    class="explore-plans-button"
                    button-type="tagBlack"
                    size="medium"
                    @click="explorePlans"
                >Explore plans</BaseButton>
            </div>
        </BaseCard>
        <div class="side-cards">
            <div
                v-if="!hasActivePlan"
                class="side-card"
                @click="explorePlans"
            >
                <BaseCard
                    class="side-card-content fw-bold"
                    theme="gradient-vibrand-highlight"
                >
                    {{ isEntitledForTrial ? 'Start free trial' : 'Upgrade to Premium' }}
                </BaseCard>
            </div>
            <div
                v-else
                class="side-card"
                @click="goToEditProfile"
            >
                <BaseCard class="side-card-content">
                    <BaseIcon
                        class="side-card-icon"
                        name="user"
                    />
                    Edit Profile
                </BaseCard>
            </div>
            <div
                v-if="allowUpdateCard"
                class="side-card"
                @click="$emit('managePremium')"
            >
                <BaseCard :class="['side-card-content', { 'skeleton': isStripePortalLoading }]">
                    <BaseIcon
                        class="side-card-icon"
                        name="wallet"
                    />
                    Update card
                </BaseCard>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@design';

.subscription-header {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: stretch;
    column-gap: $s-m;
}

.plan-card {
    min-height: 170px;
    flex: 2 1 0%;
}

.plan-header {
    @extend %contentTitleM;
}

.plan-name {
    @extend %h4;

    margin-top: $s-xs;
    margin-bottom: 0;
}

.plan-subtitle {
    margin-top: $s-xxs;
}

.plan-actions {
    display: flex;
    aligh-items: center;
    justify-content: flex-end;
    margin-top: $s-xl;

    .explore-plans-button {
        text-transform: unset;
        font-weight: unset;
        padding: $s-m $s-l;
    }
}

.side-cards {
    display: flex;
    flex-flow: column;
    flex: 1 1 0%;
    row-gap: $s-m;
    column-gap: 0px;
}

.side-card {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        .side-card-content:not(.highlight) {
            background: $c-tuna;
        }

        .side-card-content.highlight {
            background: $c-frisky-fill;
        }
    }
}

.card-link {
    display: block;
}

.side-card-content {
    @extend %contentSubtitleM;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: $s-m;
    text-align: center;
    overflow: hidden;
}

.side-card-icon {
    max-height: 23px;
    max-width: 23px;
}

@include tablet {
    .plan-card {
        flex: 2.5 1 0%;
    }
}
</style>
