<script>
import Logo from './../../../components/logo'
import Donate from './tabs/donate.vue'
import ViewPlans from './tabs/view-plans.vue'
import analyticsService from './../../../services/analytics-service'

export default {
    name: 'PremiumViewPlans',

    components: { Logo, Donate, ViewPlans },

    props: {
        mainComponent: {
            type: String,
            default: 'ViewPlans',
        },
    },

    computed: {
        isLoggedIn() {
            return this.$store.getters['auth/loggedIn']
        },
        plans() {
            return this.$store.getters['premium/sortedPremiumPlans']
        },
        currentSubscription() {
            return this.$store.getters['premium/activeSubscription']
        },
        entitledFreeTrial() {
            return !this.$store.getters['premium/hasEverBeenPremium']
        },
        filteredPlans() {
            return this.plans.filter(plan => plan.active === 1)
        },
        tabs() {
            return [
                {
                    to: `/premium/view-plans`,
                    label: 'Premium',
                },
                {
                    to: `/premium/donate`,
                    label: 'Donate',
                },

            ]
        },
    },

    methods: {
        selectPlan(plan) {
            analyticsService.premiumUpsellPlanSelected(plan.id)
            this.$router.push({
                path: `${this.$route.path.includes('offer') ? '/offer' : ''}/premium/select-payment/${plan.id}`,
            })
        },
        selectAmount(amount) {
            this.selectedDonationAmount = amount
        },
    },
}
</script>

<template>
    <div>
        <BaseContent v-if="tabs.length">
            <BaseSection padded>
                <Logo
                    :is-linked-to-home="false"
                    size="m"
                />
            </BaseSection>
            <BaseTabPanel
                :tabs="tabs"
                :class="$style.tabs"
                is-unpadded
                address-navigation
            />
            <BaseContent>
                <component
                    :is="mainComponent"
                    :plans="filteredPlans"
                    :current-subscription="currentSubscription"
                    :is-logged-in="isLoggedIn"
                    :is-entitled-free-trial="entitledFreeTrial"
                    @selectAmount="selectAmount"
                    @onAction="selectPlan"
                />
            </BaseContent>
            <BaseSection padded>
                <BaseLink :to="{ name: 'home' }"><div :class="$style.escapeUrl">No, thanks</div></BaseLink>
            </BaseSection>
        </BaseContent>
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.escapeUrl {
    @extend %contentSubtitleM;

    margin-top: $s-xxl;
    text-align: center;
    text-decoration: underline;
}

@include tablet {
    .escapeUrl {
        text-align: left;
    }
}
</style>
