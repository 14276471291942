
<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    }
}
</script>

<template>
    <div class="item">
        <BaseLink
            :to="to"
            :href="href"
            class="link"
        >
            <div class="prepend-icon-container">
                <BaseIcon
                    :name="icon"
                    class="prepend-icon"
                />
            </div>
            <div class="text">{{ text }}</div>
            <div class="append-icon-container">
                <BaseIcon
                    v-if="!isLoading"
                    name="chevron-right"
                    class="append-icon"
                />
                <BaseIcon
                    v-else
                    name="spinner"
                    class="append-icon spin-icon spin"
                />
            </div>
        </BaseLink>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.item {
    border-radius: 5px;
}

.item ~ .item {
    margin-top: $s-m;
}

.link {
    display: flex;
    align-items: center;
    gap: $s-s;
    color: $c-white;
    padding: $s-xs $s-m $s-xs $s-xs;
    text-decoration: none;
}

.item:nth-of-type(odd) {
    background-color: $c-active-bg;

    &:hover {
        background-color: $c-tuna;
    }
}

.item:nth-of-type(even) {
    background-color: transparent;

    &:hover {
        background-color: $c-tuna;
    }
}

.prepend-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: $s-xs;
    background-color: $c-tuna;
    border-radius: 4px;
}

.prepend-icon {
    max-width: 100%;
    max-height: 100%;
}

.append-icon-container {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.append-icon {
    width: 12px;
    height: 16px;
    fill: $c-text-faded;
}

.spin-icon {
    width: 23px;
    height: 23px;
}
</style>
