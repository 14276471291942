<script>
import DataLayer from '@services/data-layer/index'
import BoxedLayout from '@layouts/boxed'
import Logo from '@components/logo'
import analyticsService from '@services/analytics-service'

export default {
    components: { BoxedLayout, Logo },
    data() {
        return {
            hasChanges: false,
            newImage: null,
            s3Folder: process.env.VUE_APP_BFH_IMAGES_FOLDER,
            isImageLoading: false,
        }
    },
    page() {
        const title = 'Account Management'
        const description = 'Account Management'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        isPassSet() {
            return this.$store.state.member.passState === 'set'
        },
        user() {
            return this.$store.getters['member/currentMember']
        },
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        }
    },
    methods: {
        uploadImage(e) {
            this.isImageLoading = true
            const files = e.target.files
            DataLayer.uploader
                .upload(files, this.s3Folder)
                .then(image => {
                    this.updateUserAvatar(image.body)
                })
                .catch(() => {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                })
        },
        updateUserAvatar(newImage) {
            if (!newImage) return
            const user = { ...this.user, avatar: newImage }
            DataLayer.members
                .edit({ id: this.user.id, data: user })
                .then(user => {
                    this.newImage = null
                    this.isImageLoading = false
                    this.$store.dispatch('member/updateUser', user.body)
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('DETAILS_UPDATED'),
                        type: 'success',
                    })
                }).catch(() => {
                    this.isImageLoading = false
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                })
        },
        onPremiumMenuItemClick() {
            analyticsService.premiumUpsellInitiated('account-premium-menu-item')
        }
    }
}
</script>

<template>
    <BoxedLayout>
        <Logo/>
        <router-view class="router-content"/>
    </BoxedLayout>

</template>

<style lang="scss" scoped>
@import '@design';

.router-content {
    margin-top: $s-xl;
}

@include tablet {
    .router-content {
        margin-top: $s-xxl;
    }
}

@include desktop {
    .router-content {
        margin-top: $s-h;
    }
}
</style>
