import { sortByProperty } from './../../utils/array'

export const state = {
    plans: [],
    subscriptions: [],
    selectedPlanId: null,
    currentTransaction: null,
    isLoading: true
}

export const getters = {
    activeSubscription(state) {
        const currentSubscription = state.subscriptions.length > 0 ? state.subscriptions[state.subscriptions.length - 1] : null
        if (!currentSubscription) return null
        if(new Date(currentSubscription.endTime ) < new Date()) return null
        let currentPlan = state.plans.find(plan => plan.id === currentSubscription.subscriptionsProductsId.id)
        currentSubscription.plan = currentPlan
        return currentSubscription
    },
    currentSubscription(state, getters) {
        return getters.activeSubscription || getters.freeSubscription
    },
    freeSubscription(state) {
        return { plan: state.plans.find(plan => plan.price === 0) }
    },
    hasEverBeenPremium(state) {
        return state.subscriptions.length > 0
    },
    isPremium(state, getters) {
        return !!getters.activeSubscription
    },
    sortedPremiumPlans(state) {
        return sortByProperty({ array: [...state.plans], property: 'price', direction: 'desc' })
            .filter(plan => plan.price > 0)
    },
    selectedPlan(state) {
        return state.plans.find(plan => plan.id === Number(state.selectedPlanId))
    },
    paymentMethod(state) {
        return state.currentTransaction?.paymentProcessor
    },
    hasPaidViaMobile(state, getters) {
        const desktopPaymentMethods = ['stripe', 'paypal', 'manual']
        return getters.activeSubscription && !desktopPaymentMethods.includes(getters.paymentMethod)
    },
}

export const mutations = {
    SET_PLANS(state, plans) {
        state.plans = plans
    },
    SET_SUBSCRIPTIONS(state, subscriptions) {
        state.subscriptions = subscriptions
    },
    SET_SELECTED_PLAN_ID(state, planId) {
        state.selectedPlanId = planId
    },
    SET_ACTIVE_TRANSACTION(state, transaction) {
        state.currentTransaction = transaction
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
}

export const actions = {}
