<script>
import { centsToDollars } from '../../../utils/format-price'
import formatDate  from './../../../utils/format-date-full'

export default {
    page() {
        const title = 'Account - Subscription management'
        const description = 'Account - Subscription management'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    data() {
        return {
            crumbs: [
                { label: 'Account Management', to: '/account' },
                { label: 'Manage subscription' },
            ],
        }
    },
    computed: {
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        },
        hasPaidViaMobile() {
            return this.$store.getters['premium/hasPaidViaMobile']
        },
        hasActivePlan() {
            return ['active', 'trialing'].includes(this.currentSubscription?.status)
        },
        currentPlan() {
            return this.hasActivePlan ? `Premium` : 'FRISKY Free'
        },
        paymentMethod() {
            return this.$store.getters['premium/paymentMethod']
        },
        isLoading() {
            return this.$store.state.premium.isLoading
        },
        paymentDetails() {
            if(!this.hasActivePlan) return ''

            if (this.hasPaidViaMobile) {
                return 'Your subscription is managed through Apple. To manage your subscription, go to iOS Settings > Apple ID > Subscriptions'
            }

            if (this.paymentMethod === 'manual') {
                return `Enjoy free Premium subscription on us! It will expire on ${formatDate(new Date(this.currentSubscription.endTime))}`
            }

            if(this.currentSubscription?.cancelTime) {
                return `Your subscription is canceled. It will expire on ${formatDate(new Date(this.currentSubscription.endTime))}`
            }

            return `Your next bill is for ${ centsToDollars(this.currentSubscription.plan.price) } on ${ formatDate(new Date(this.currentSubscription.endTime)) }`
        }
    },
    methods: {
        onCancelRequest() {
            this.$router.push({ name: 'account/cancel-premium' })
        },
    },
}
</script>

<template>
    <div>
        <BaseBreadcrumbs :crumbs="crumbs" />
        <div
            v-if="isLoading"
            class="cards"
        >
            <BaseCard
                theme="gradient-highlight"
                class="skeleton card"
            />
            <BaseCard class="skeleton card"/>
        </div>
        <div
            v-else
            class="cards"
        >
            <BaseCard
                class="card plan-card"
                theme="gradient-highlight"
            >
                <h2 class="plan-header">Your plan</h2>
                <h3 class="plan-title">{{ currentPlan }}</h3>
                <ul
                    v-if="currentPlan === 'Premium'"
                    class="plan-details-list fw-medium"
                >
                    <li>No ads anywhere</li>
                    <li>High resolution audio streaming</li>
                    <li>Download mixes on mobile and listen offline</li>
                    <li>Streaming in external players and devices</li>
                    <li>Premium-exclusive mixes</li>
                    <li>Customer support by humans</li>
                </ul>
                <br>
            </BaseCard>
            <BaseCard class="card next-payment-card">
                <h2 class="payment-header text-faded">Payment</h2>
                <p class="payment-details text-faded">{{ paymentDetails }}</p>
            </BaseCard>
        </div>
        <div
            v-if="!isLoading"
            class="actions"
        >
            <BaseButton
                v-if="!currentSubscription.cancelTime"
                class="cancel-subscription-button"
                button-type="tagBlack"
                size="medium"
                type="button"
                @click="onCancelRequest"
            >Cancel subscription</BaseButton>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@design' as *;

.cards {
    margin-top: $s-xl;
}

.card {
    min-height: 230px;
}

.plan-header {
    @extend %contentTitleM;
}

.plan-title {
    @extend %h4;

    margin: $s-m 0;
    line-height: auto;
}

.plan-details-list {
    padding-left: $s-l;
    font-size: 14px;
    line-height: 18px;
    color: $c-white;
}

.payment-header {
    @extend %h5;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $s-xl;
    margin-top: $s-xxl;

    .cancel-subscription-button {
        text-transform: unset;
        font-weight: unset;
        padding: $s-m $s-l;
    }
}

@include tablet {
    .actions {
        justify-content: flex-end;
    }
}
</style>
