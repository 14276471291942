<script>
import formatDate  from './../../../utils/format-date-full'
import SubscriptionHeader from './partials/subscription-header.vue'
import DataLayer from '@services/data-layer/index'

export default {
    page() {
        const title = 'Account - Overview'
        const description = 'Account - Overview'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { SubscriptionHeader },
    data() {
        return {
            isStripePortalLoading: false,
        }
    },
    computed: {
        user() {
            return { ...this.$store.state.member.currentMember }
        },
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        },
        paymentMethod() {
            return this.$store.getters['premium/paymentMethod']
        },
        hasActivePlan() {
            return ['active', 'trialing'].includes(this.currentSubscription?.status)
        },
        isLoading() {
            return this.$store.state.premium.isLoading
        },
        showManagementSection() {
            return this.paymentMethod === 'stripe' && (this.currentSubscription.plan.active === 1 || this.currentSubscription.plan.durationMonths === 1 ) || this.paymentMethod === 'paypal'
        },
        showViewPlansSection() {
            return !this.hasActivePlan || (this.paymentMethod === 'stripe' && this.currentSubscription.plan.active === 1)
        },
        showCardSection() {
            return this.paymentMethod === 'stripe' && (this.currentSubscription.plan.active === 1 || this.currentSubscription.plan.durationMonths === 1 )
        },
        showSubscriptionSection() {
            return this.showManagementSection || this.showViewPlansSection || this.showCardSection
        },
    },
    methods: {
        formatDate,
        goToStripePortal() {
            this.isStripePortalLoading = true
            DataLayer.subscriptions.getStripePortalUrl()
                .then(response => {
                    window.location.href = response.body.portalUrl
                    this.isStripePortalLoading = false
                })
                .catch(() => {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                    this.isStripePortalLoading = false
                })
        },
        handlePremiumManagement(event) {
            if(this.paymentMethod === 'stripe' && this.currentSubscription.plan.active === 1) this.goToStripePortal()
            else this.$router.push('/account/manage-subscription')
        },
    },
}
</script>

<template>
    <div>
        <div v-if="isLoading">
            <div class="tiles">
                <BaseCard class="skeleton loader"/>
                <BaseCard class="skeleton loader"/>
                <BaseCard class="skeleton loader"/>
                <BaseCard class="skeleton loader"/>
            </div>
        </div>
        <div
            v-else
            class="tiles"
        >
            <SubscriptionHeader
                :is-stripe-portal-loading="isStripePortalLoading"
                :allow-update-card="showCardSection"
                @managePremium="handlePremiumManagement"
            />

            <BaseCard class="card-section">
                <div slot="header">
                    <h3 class="card-header">Your Account</h3>
                    <div class="card-links">
                        <BaseCardLink
                            text="Edit Profile"
                            to="/account/edit-profile"
                            icon="user"
                        />
                    </div>
                </div>
            </BaseCard>
            <BaseCard
                v-if="showSubscriptionSection"
                class="card-section"
            >
                <div slot="header">
                    <h3 class="card-header">Subscription</h3>
                    <BaseCardLink
                        v-if="showManagementSection"
                        text="Manage subscription"
                        icon="frisky"
                        :is-loading="isStripePortalLoading"
                        @click.native="handlePremiumManagement"
                    />
                    <BaseCardLink
                        v-if="showViewPlansSection"
                        text="Available plans"
                        to="/premium/view-plans"
                        icon="cassette"
                    />
                    <BaseCardLink
                        v-if="showCardSection"
                        class="update-card-link"
                        text="Update card"
                        icon="wallet"
                        :is-loading="isStripePortalLoading"
                        @click.native="handlePremiumManagement"
                    />
                </div>
            </BaseCard>
            <BaseCard class="card-section">
                <div slot="header">
                    <h3 class="card-header">Security</h3>
                    <div class="card-links">
                        <BaseCardLink
                            text="Change password"
                            to="/account/change-password"
                            icon="locker"
                        />
                    </div>
                </div>
            </BaseCard>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.tiles {
    display: flex;
    flex-direction: column;
    gap: $s-l;

    .card-section {
        padding-bottom: $s-xxxl;
    }
}

.card-header {
    @extend %h5;

    color: $c-text-faded;
}

.loader {
    min-height: 200px;
}

@include tablet {
    .tiles {
        gap: $s-xxl;
    }
}
</style>
