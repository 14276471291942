export default (playerService, queueService) => {
    const reactToKey = e => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'ArrowUp') {
            e.preventDefault()
            playerService.volumeUp()
        } else if ((e.ctrlKey || e.metaKey) && e.key === 'ArrowDown') {
            e.preventDefault()
            playerService.volumeDown()
        } else if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.key === 'Backspace') {
            e.preventDefault()
            playerService.toggleMute()
        } else if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.key === 'ArrowLeft') {
            e.preventDefault()
            queueService.playPrevious()
        } else if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.key === 'ArrowRight') {
            e.preventDefault()
            queueService.playNext()
        } else if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowLeft') {
            e.preventDefault()
            playerService.seekBackwards()
        } else if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowRight') {
            e.preventDefault()
            playerService.seekForward()
        }
    }

    return { reactToKey }
}
