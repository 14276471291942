import Api from './../api'
import DataLayer from './../data-layer/index'

DataLayer.$extend('subscriptions', {
    getProducts() {
        return Api.subscriptions.getProducts({})
    },

    getSubscriptionsByMemberId(membersid) {
        return Api.subscriptions.getSubscriptions({ membersid })
    },

    getActiveSubscriptionsByMemberId(membersid) {
        return Api.subscriptions.getSubscriptions({ membersid, status: 'active' })
    },

    checkout(data) {
        return Api.subscriptions.checkout({}, data)
    },

    getCheckoutById(id) {
        return Api.subscriptions.getCheckout({ id })
    },

    cancelSubscription({ id }) {
        const payload = {
            status: 'canceled'
        }

        return Api.subscriptions.editSubscription({ id }, payload)
    },

    getStripePortalUrl() {
        const returnUrl = window.location.pathname.substring(1)
        return Api.subscriptions.getStripePortalUrl({ returnurl: returnUrl })
    }
})
