
<script>
export default {
    props: {
        theme: {
            type: String,
            default: 'default', // default, highlight, gradient-highlight, gradient-vibrand-highlight
            validator: function(value) {
                return ['default', 'highlight', 'gradient-highlight', 'gradient-vibrand-highlight'].includes(value)
            }
        },
    }
}
</script>

<template>
    <div
        class="card"
        :class="theme"
    >
        <div
            v-if="$slots['header']"
            class="cardHeader"
        >
            <slot name="header"/>
        </div>
        <slot />
        <div
            v-if="$slots['footer']"
            class="card-footer"
        >
            <slot name="footer"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.card {
    padding: $s-s;
    border-radius: $s-xs;

    // THEMES
    &.default {
        background-color: $c-shark-grey;
    }

    &.highlight {
        background-color: $c-highlight;
    }

    &.gradient-highlight {
        background: linear-gradient(86.16deg, rgba(223, 223, 65, 0) 22.91%, rgba(223, 223, 65, 0.2) 87.36%), linear-gradient(86.54deg, rgba(251, 173, 19, 0) 9.79%, rgba(0, 0, 0, 0.2) 60.98%), rgba(237, 7, 86, 0.466667);
    }

    &.gradient-vibrand-highlight {
        background: linear-gradient(241.26deg, rgba(255, 213, 0, 0.2208) 32.31%, rgba(109, 16, 16, 0) 102.56%), #FF0482;
    }
}

.card-content {
    position: relative;
    height: 100%;
    width: 100%;
}

.card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

@include tablet {
    .card {
        padding: $s-l;
    }
}
</style>
