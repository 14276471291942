import Api from './../api'
import DataLayer from './../data-layer/index'

DataLayer.$extend('messages', {
    sendMessage({ message, subject, senderId, recipientId, content }) {
        const payload = {
            content,
            sender: senderId,
            email: {
                body: message,
                subject,
            },
            mode: "EMAIL", // SYSTEM, USER, EMAIL
            recipient: recipientId,
            type: "NOTIFICATION", // NOTIFICATION, WARNING, BILLING
        }

        return Api.messages.sendMessage({}, payload)
    }
})
