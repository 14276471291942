<script>
export default {
    props: {
        genre: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        alias() {
            return this.genre.name.replace(/\s+/g, '_').toLowerCase()
        },
    },
}
</script>

<template>
    <div :title="`Discover ${genre.name} music`">
        <BaseLink
            v-if="genre"
            :to="{ name: 'genre', params: { id: alias } }"
        >
            <div class="genre-tile-container">
                <BaseResourceImage
                    :resource="genre"
                    type="artwork"
                    class="genre-tile-image"
                    :fill="false"
                />
                <div class="genre-tile-title">
                    {{ genre.name }}
                </div>
            </div>
        </BaseLink>
        <BasePlaceholder
            v-else
            class="genre-tile-title"
            :text="genre && genre.name"
            type="genre"
            tag="div"
        />
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.genre-tile-container {
    position: relative;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $s-l;
    overflow: hidden;
    border: 2px solid $c-grey-mine-shaft;
}

.genre-tile-image {
    align-self: end;
    font-size: 0;
    transition: transform 0.3s;
}

.genre-tile-title {
    @extend %contentTitleL;

    position: absolute;
    align-self: center;
    line-height: 24px;
    text-align: center;
}

@include desktop {
    .genre-tile-container {
        height: 200px;

        &:hover {
            .genre-tile-image {
                transform: scale(1.1);
            }
        }
    }
}
</style>
