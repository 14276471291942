import { render, staticRenderFns } from "./_base-card.vue?vue&type=template&id=a5faf6b6&scoped=true&"
import script from "./_base-card.vue?vue&type=script&lang=js&"
export * from "./_base-card.vue?vue&type=script&lang=js&"
import style0 from "./_base-card.vue?vue&type=style&index=0&id=a5faf6b6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5faf6b6",
  null
  
)

export default component.exports