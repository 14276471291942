<script>
export default {
    components: {  },
    props: {},
    data() {
        return {}
    },
    computed: {},
    mounted() {},
}
</script>

<template>
    <div class="boxed-container">
        <main>
            <slot />
        </main>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.boxed-container {
    width: $desktop;
    max-width: 100%;
    margin: 0 auto;
    padding: $s-xl $s-xs;
}

@include tablet {
    .boxed-container {
        padding: $s-xxl $s-m 0;
    }
}
</style>
