<script>
import DataLayer from '@services/data-layer/index'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import Birthday from '@data/models/Birthday'
import months from '@enums/months'

export default {
    page() {
        const title = 'Account - Edit Profile'
        const description = 'Account - Edit Profile'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    mixins: [validationMixin],
    data() {
        return {
            user: null,
            hasChanges: false,
            isLoaded: false,
            months,
            currentYear: new Date().getFullYear(),
            startYear: 1900,
            newImage: null,
            s3Folder: process.env.VUE_APP_BFH_IMAGES_FOLDER,
            imageLoading: false,
            crumbs: [
                { label: 'Account Management', to: '/account' },
                { label: 'Edit profile' },
            ],
        }
    },
    computed: {
        birthday() {
            let birthday = new Birthday()
            if (this.user && this.user.birthday) {
                birthday.day = new Date(this.user.birthday).getDate()
                birthday.month = new Date(this.user.birthday).getMonth()
                birthday.year = new Date(this.user.birthday).getFullYear()
            }
            return birthday
        },
        years() {
            const years = []
            for (let i = this.startYear; i <= this.currentYear; i++) {
                years.push(i)
            }
            return years
        },
    },
    watch: {
        '$store.state.member.currentMember':{
            handler() {
                this.loadInitUser()
            },
        },
        user: {
            deep: true,
            handler() {
                this.hasChanges = this.isLoaded
                this.isLoaded = true
            },
        },
    },
    mounted() {
        this.loadInitUser()
    },
    methods: {
        loadInitUser() {
            this.user = { ...this.$store.state.member.currentMember }
        },
        setDate() {
            if (this.birthday.day && this.birthday.month && this.birthday.year) {
                const timezoneOffset = new Date().getTimezoneOffset()
                const newDate = new Date(
                    this.birthday.year,
                    this.birthday.month,
                    this.birthday.day,
                    0,
                    -timezoneOffset
                ).toJSON()
                this.user.birthday = newDate
            }
        },
        save() {
            if (!this.$v.$invalid) {
                DataLayer.members
                    .edit({ id: this.user.id, data: this.user })
                    .then(user => {
                        this.hasChanges = false
                        this.$store.commit('member/SET_CURRENT_MEMBER', user.body)
                        this.$store.dispatch('appearance/handleNotification', {
                            message: this.$m('DETAILS_UPDATED'),
                            type: 'success',
                        })
                        this.$router.push('/account')
                    })
                    .catch(error => {
                        if (error.response.status === 409) {
                            this.$store.dispatch('appearance/handleNotification', {
                                message: this.$m('EMAIL_ALREADY_EXISTS'),
                                type: 'danger',
                            })
                            return
                        }
                        this.$store.dispatch('appearance/handleNotification', {
                            message: this.$m('SOMETHING_WENT_WRONG'),
                            type: 'danger',
                        })
                    })
            } else {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('EMAIL_REQUIRED'),
                    type: 'warning',
                })
                this.$v.$reset()
            }
        },
        uploadImage(e) {
            this.imageLoading = true
            const files = e.target.files
            DataLayer.uploader
                .upload(files, this.s3Folder)
                .then(image => {
                    this.user.avatar = image.body
                    this.newImage = null
                    this.imageLoading = false
                })
                .catch(_error => {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                })
        },
        goBack() {
            this.$router.push('/account')
        },
    },
    validations: {
        user: {
            email: {
                required,
                email,
            },
        },
    },
}
</script>

<template>
    <div>
        <BaseBreadcrumbs :crumbs="crumbs"/>
        <h1 class="title">Edit Profile</h1>
        <form
            v-if="user"
            @submit.prevent="save"
        >
            <div class="form-container">
                <div class="field-container">
                    <label class="label">Email</label>
                    <BaseInput
                        v-model.lazy="user.email"
                        :class="['field', $v.user.email.$error && 'error']"
                        placeholder="Enter email"
                    />
                </div>
                <div class="field-container">
                    <label class="label">First name</label>
                    <BaseInput
                        v-model.lazy="user.firstName"
                        class="field"
                        placeholder="Enter first name"
                    />
                </div>
                <div class="field-container">
                    <label class="label">Last name</label>
                    <BaseInput
                        v-model.lazy="user.lastName"
                        class="field"
                        placeholder="Enter last name"
                    />
                </div>
                <div class="field-container">
                    <label class="label">Date of Birth</label>
                    <div class="birthday-fields">
                        <BaseSelect
                            v-model="birthday.day"
                            @change="setDate"
                        >
                            <option
                                class="option"
                                :value="null"
                                disabled
                                selected
                            >Day</option>
                            <option
                                v-for="d in 31"
                                :key="d + 'day'"
                                :value="d"
                                class="option"
                            >
                                {{ d }}
                            </option>
                        </BaseSelect>
                        <BaseSelect
                            v-model="birthday.month"
                            @change="setDate"
                        >
                            <option
                                class="option"
                                :value="null"
                                disabled
                                selected
                            >Month</option>
                            <option
                                v-for="month in months"
                                :key="month.title + month.value"
                                :value="month.value"
                                class="option"
                            >
                                {{ month.title }}
                            </option>
                        </BaseSelect>
                        <BaseSelect
                            v-model="birthday.year"
                            @change="setDate"
                        >
                            <option
                                class="option"
                                :value="null"
                                disabled
                                selected
                            >Year</option>
                            <option
                                v-for="y in years"
                                :key="y + 'day'"
                                :value="y"
                                class="option"
                            >
                                {{ y }}
                            </option>
                        </BaseSelect>
                    </div>
                </div>
            </div>
            <div class="actions">
                <BaseButton
                    button-type="transparent"
                    size="medium"
                    type="button"
                    @click="goBack"
                >
                    <span>Cancel</span>
                </BaseButton>
                <BaseButton
                    class="save-button"
                    :disabled="!hasChanges"
                    button-type="tagBlack"
                    size="medium"
                    type="submit"
                    @click="() => {}"
                >Save profile</BaseButton>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.title {
    margin-top: 0;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: $s-l;
}

.field-container {
    display: flex;
    flex-direction: column;
    gap: $s-m;
}

.label {
    @extend %contentSubtitleM;
}

.birthday-fields {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-gap: $s-xs;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $s-xl;
    margin-top: $s-xxl;

    .save-button {
        text-transform: unset;
        font-weight: unset;
        padding: $s-m $s-l;
    }
}

@include tablet {
    .birthday-fields {
        grid-template-columns: 1fr 2fr 4fr;
        grid-gap: $s-s;
    }

    .actions {
        justify-content: flex-end;
    }
}
</style>
