<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li
                v-for="(crumb, index) in crumbs"
                :key="index"
                class="breadcrumb-item"
                :class="{ active: index === crumbs.length - 1 }"
            >
                <span v-if="index !== crumbs.length - 1">
                    <router-link
                        v-if="crumb.to"
                        :to="crumb.to"
                        class="breadcrumb-link"
                    >{{ crumb.label }}</router-link>
                    <span v-else>{{ crumb.label }}</span>
                </span>
                <span
                    v-else
                    class="active"
                >{{ crumb.label }}</span>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    name: 'BaseBreadcrumbs',
    props: {
        crumbs: {
            type: Array,
            required: true,
            validator(value) {
                return value.every(crumb => crumb.label && (crumb.to === undefined || typeof crumb.to === 'string'))
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@design';

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
}

.breadcrumb-item {
    @extend %contentTitleM;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    padding: 0 $s-xxs;
    color: $c-white;
}

.breadcrumb-item.active {
    color: $c-text-faded;
}

.breadcrumb-link {
    color: $c-white;
    text-decoration: none;

    &:hover {
        color: $c-highlight;
    }
}

@include tablet {
    .breadcrumb-item + .breadcrumb-item::before {
        padding: 0 $s-s;
    }
}
</style>
